<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      :footer="true"
      :centered="true"
      @ok="handleCancel"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <div style="display: flex;justify-content: space-between">
        <a v-action:show_ewfy @click="showAdditionalCosts">增加额外费用</a>
        <a v-action:log v-if="algorithm==1" @click="handleCommission">提成明细</a>
        <!--        <a v-action:show_ffjl @click="showGrantLog">发放记录</a>-->
      </div>
      <div class="table" style="margin-top: 10px">
        <div class="tr">
          <div class="td">基本工资</div>
          <div class="td">{{ record.basic_salary }}</div>
        </div>
        <div class="tr" v-for="item in record.fixed_subsidy_list">
          <div class="td">{{ item.name }}</div>
          <div class="td">{{ item.amount }}</div>
        </div>

        <div class="tr" v-if="algorithm==2">
          <div class="td">已跑公里数</div>
          <div class="td">
            {{ record.kilometer }}km
            <a @click="showsk()">设置</a>
          </div>
        </div>
        <div class="tr">
          <div class="td">提成工资</div>
          <div class="td">{{ record.commission }}</div>
        </div>
        <div class="tr" v-for="item in record.askleave_list">
          <div class="td" style="color: red">请假扣除</div>
          <div class="td">
            <span style="color: red">-{{ item.amount }}</span>
          </div>
        </div>
        <div class="tr" v-for="item in record.additional_list">
          <div class="td">{{ item.additional_name }}</div>
          <div class="td">
            <span style="color: red" v-if="item.additional_type==1">-{{ item.amount }}</span>
            <span style="color: #04be02" v-else>+{{ item.amount }}</span>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="info-item" style="font-size: 16px"> 实发工资：<span style="color: red">￥</span>{{
            record.net_salary
          }}
        </div>
      </div>
      <div class="info">
        <div class="info-item"> 当前状态：
          <a-tag v-if="record.status==0">待审核</a-tag>
          <a-tag v-if="record.status==1" color="orange">待核对</a-tag>
          <a-tag v-if="record.status==2" color="green">待发放</a-tag>
          <a-tag v-if="record.status==3" color="cyan">待签收</a-tag>
          <a-tag v-if="record.status==4" color="blue">已完成</a-tag>
          <a-tag v-if="record.status==5" color="red">核对有误</a-tag>
        </div>
        <div class="info-item" v-if="record.status==5"> 有误信息：<span style="color: red">{{ record.reason }}</span>
        </div>
      </div>
      <template v-if="record.status>=1">
        <div class="info">
          <div class="info-item"> 审核人：{{ record.audit_user.real_name }}</div>
          <div class="info-item"> 审核时间：{{ record.audit_time }}</div>
        </div>
      </template>
      <template v-if="record.status>=2">
        <div class="info">
          <div class="info-item"> 核对人：{{ record.personnel.personnel_name }}</div>
          <div class="info-item"> 核对时间：{{ record.check_time }}</div>
        </div>
      </template>
      <template v-if="record.status>=3 && record.status!=5">
        <div class="info">
          <div class="info-item"> 发放人：{{ record.grant_user.real_name }}</div>
          <div class="info-item"> 发放时间：{{ record.grant_time }}</div>
          <div class="info-item"> 发放方式：{{ grant_type_texts[record.grant_type] }}</div>
        </div>
      </template>
      <template v-if="record.status>=3 && record.status!=5 && record.grant_list.length>0">
        <div class="info">
          <div class="info-item"> 发放记录：</div>
          <table border style="text-align: center;table-layout:fixed;border-color: #e8e8e8;width:100%;margin-top: 10px">
            <tr>
              <td class="title" style="width: 80px;padding: 5px">发放人</td>
              <td class="title" style="width:80px">发放方式</td>
              <td class="title" style="width:80px">发放金额</td>
              <td class="title" style="width:80px">发放时间</td>
            </tr>
            <tr v-for="item in record.grant_list">
              <td class="title" style="width: 80px;padding: 5px">{{ item.grant_user.real_name }}</td>
              <td class="title" style="width:80px">{{ grant_type_texts[item.grant_type] }}</td>
              <td class="title" style="width:80px">{{ item.amount }}</td>
              <td class="title" style="width:80px">{{ item.create_time }}</td>
            </tr>
          </table>
        </div>
      </template>
      <template v-if="record.status>=4 && record.status!=5">
        <div class="info">
          <div class="info-item" v-if="record.sign_file"> 签收人：
            <img style="margin-left:20px;height: 100px;transform: rotate(-90deg);" :src="record.sign_file.external_url">
          </div>
          <div class="info-item"> 签收时间：{{ record.sign_time }}</div>
        </div>
      </template>
      <div style="text-align: center" v-action:show_audit>
        <template v-if="record.status==0">
          <a-button @click="editStatus(1)" type="primary">标记当前已审</a-button>
        </template>
        <template v-if="record.status==2">
          <div style="margin: 20px 0">
            选择发放方式：
            <a-radio-group v-model="grant_type">
              <a-radio :value="0">
                微信
              </a-radio>
              <a-radio :value="1">
                现金
              </a-radio>
              <a-radio :value="2">
                银行转账
              </a-radio>
            </a-radio-group>
          </div>
          <a-button @click="editStatus(3)" type="primary">确认发放工资</a-button>
        </template>
        <template v-if="record.status==5">
          <a-button @click="editStatus(1)" type="primary">标记已重新审核</a-button>
        </template>
      </div>
    </a-spin>
    <CommissionModel
        ref="CommissionModel"
        @handleSubmit="getData"
    />
    <AdditionaModel
        ref="AdditionaModel"
        @handleSubmit="getData"
    />
    <GrantLogModel
        ref="GrantLogModel"
        @handleSubmit="getData"
    />
    <SettlementKilometers
        ref="SettlementKilometers"
        @handleSubmit="getData"
    />
  </a-modal>
</template>

<script>
import * as Api from '@/api/personnel/payroll'
import * as settingApi from "@/api/setting/store";

import {UploadFile} from '@/components'
import {isEmpty} from "@/utils/util";
import AdditionaModel from "./AdditionaModel";
import SettlementKilometers from "./SettlementKilometers.vue";
import GrantLogModel from "../../expenditure/modules/GrantLogModel";
import CommissionModel from "./CommissionModel.vue";

export default {
  components: {
    CommissionModel,
    AdditionaModel,
    GrantLogModel,
    UploadFile,
    SettlementKilometers
  },
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},

      payroll_id: '',
      grant_type: 0,
      grant_type_texts: [
        '微信', '现金', '银行转账'
      ],
      algorithm: ''
    }
  },
  methods: {
    showsk() {
      this.$refs.SettlementKilometers.open(this.record)
    },
    isEmpty,
    showAdditionalCosts() {
      this.$refs.AdditionaModel.queryParam.payroll_id = this.record.payroll_id
      this.$refs.AdditionaModel.queryParam.payroll_type = 0
      this.$refs.AdditionaModel.open()
    },

    handleCommission() {
      console.log(this.record)
      this.$refs.CommissionModel.queryParam.personnel_id = this.record.personnel_id
      this.$refs.CommissionModel.queryParam.personnel_type = this.record.personnel.personnel_type
      this.$refs.CommissionModel.queryParam.month_time = this.record.month_time
      this.$refs.CommissionModel.open(this.record)
    },


    showGrantLog() {
      this.$refs.GrantLogModel.queryParam.payroll_id = this.record.payroll_id
      this.$refs.GrantLogModel.open()
    },

    async getData() {
      this.confirmLoading = true
      const {data: {details}} = await Api.getDetails({payroll_id: this.payroll_id})
      this.record = details
      this.confirmLoading = false
    },

    getSettingDetail() {
      this.isLoading = true
      settingApi.detail('salary')
          .then(result => {
            this.algorithm = result.data.values.algorithm
          })
          .finally(result => {
            this.isLoading = false
          })
    },

    async editStatus(status) {
      const form = {
        status
      }
      if (status == 3) {
        form.grant_type = this.grant_type
      }
      await Api.saveStatus({payroll_id: this.payroll_id, form})
      this.getData()
    },

    open(record) {
      this.title = '查看明细'
      this.visible = true
      this.payroll_id = record.payroll_id
      this.getData()
      this.getSettingDetail()
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
      this.$emit('handleSubmit')

    },
  }
}
</script>

<style lang="less" scoped>
.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d9d9d9 solid;
    height: 40px;
    line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      height: 40px;
      width: 100%;
      text-align: center;
      border-right: 1px #d9d9d9 solid;

      &:last-child {
        border-right: none;
      }
    }
  }
}

.info {
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .info-item {
    width: 50%;
    font-size: 15px;
    font-weight: bold;
  }
}

</style>
