<template>
  <a-modal
      :title="title"
      :width="1020"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :destroyOnClose="true"
      :footer="null"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <div class="table-operator">
      </div>
      <s-table
          ref="table"
          rowKey="vr_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{y:500}"
      >
        <div slot="x_commission" slot-scope="text, item">
          <template v-if="editItem.ov_id===item.ov_id">
            <a-input
                style="width: 100px;text-align: center"
                v-model="commission"
            />
            <a style="margin-left: 10px" @click="saveCommission">保存</a>
            <a style="margin-left: 10px" @click="editItem={}">取消</a>
          </template>
          <template v-else>
            <span style="color: #04be02">+{{ text }}</span>
            <a v-action:edit_commission @click="editItem=item;commission=item.x_commission">更改</a>
          </template>
        </div>

        <div class="actions" slot="action" slot-scope="text, item">
          <a v-action:edit @click="handleEdit(item)">编辑</a>
          <a-popconfirm
              v-action:delete
              title="确认删除当前记录？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(item)"
          >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </s-table>
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/personnel/payroll'
import {STable} from "@/components";

export default {
  name: "ShowToolList",
  components: {STable},
  data() {
    return {
      title: '配套工具',
      queryParam: {
        personnel_id: '',
        personnel_type: '',
        month_time: '',
      },
      commission: '',
      editItem: {},

      visible: false,
      loading: false,
      isLoading: false,
      columns: [
        // {
        //   title: '关联订单ID',
        //   width: 60,
        //   dataIndex: 'order_id',
        //   align: 'center',
        // },
        {
          title: '起始地点',
          dataIndex: 'x_start_address',
          align: 'center',
        },
        {
          title: '到达终点',
          dataIndex: 'x_end_address',
          align: 'center',
        },
        {
          title: '提成金额',
          dataIndex: 'x_commission',
          align: 'center',
          scopedSlots: {customRender: 'x_commission'}
        },
        {
          title: '完成时间',
          dataIndex: 'complete_time',
          align: 'center',
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.getPayrollVehicle({...param, ...this.queryParam})
            .then(response => {
              response.data.list.data.map(item => {
                item.isEdit = false
              })
              return response.data.list
            })
      }
    }
  },
  methods: {
    async saveCommission() {
      var postData = {
        ol_id: this.editItem.ol_id,
        form: {}
      }
      if (this.queryParam.personnel_type == 0) {
        postData.form['driver_commission'] = this.commission
      } else {
        postData.form['supercargo_commission'] = this.commission
      }
      const {data, message} = await Api.saveCommission(postData)
      this.$message.success(message)
      this.editItem = {}
      this.handleRefresh(false)
    },

    open({personnel}) {
      this.title = (personnel.personnel_name || '') + ' - 提成明细'
      this.visible = true
    },
    cancel() {

      this.$emit('handleSubmit')
      this.visible = false
      this.editItem = {}
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },

    handleDelete(item) {
      Api.deleted({vrId: item['vr_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
